import { Form, Radio, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSendCampaignLeadToQueueMutation, useSendWholeCampaignToQueueMutation } from '../../redux-store';
import { CampaignLeadState } from '../../types';
import ChooseCampaignLeadForm from './forms/ChooseCampaignLeadForm';
import EnterCampaignLeadIdForm from './forms/EnterCampaignLeadIdForm';
import ChooseCampaignForm from './forms/ChooseCampaignForm';

export interface SelectFormValues {
  campaignId: number | null;
  campaignLeadId: number | null;
  newState: CampaignLeadState | null;
}

export interface EnterFormValues {
  campaignLeadsId: string;
  keepPrimaryStatus: boolean;
  newState: CampaignLeadState | null;
}

export interface SendWholeCampaignFormValues {
  campaignId: number | null;
  newState: CampaignLeadState | null;
  keepPrimaryStatus: boolean;
}

enum SendCampaignLeadType {
  Select = 'select',
  Enter = 'enter',
  WholeCampaign = 'wholeCampaign'
}

export interface StatusesOptions {
  value: CampaignLeadState;
  label: string;
}

export default function SqsPage() {
  const [sendCampaignLeadType, setSendCampaignLeadType] = useState<SendCampaignLeadType>(SendCampaignLeadType.Select);
  const { t: tSqs } = useTranslation('translation', { keyPrefix: 'sqs' });
  const [selectForm] = Form.useForm<SelectFormValues>();
  const [enterForm] = Form.useForm<EnterFormValues>();
  const [wholeCampaignForm] = Form.useForm<SendWholeCampaignFormValues>();
  const [sendToQueue, { isLoading: isSubmitLoading }] = useSendCampaignLeadToQueueMutation();
  const [sendWholeCampaignToQueue, { isLoading: isPushCampaignToSqsLoading }] = useSendWholeCampaignToQueueMutation();

  /**
   * Leads statuses select options
   */
  const statusesOptions = Object.values(CampaignLeadState).map((item) => ({
    value: item,
    label: tSqs(`statuses.${item}`)
  }));

  useEffect(() => {
    selectForm.resetFields();
    enterForm.resetFields();
  }, [sendCampaignLeadType]);

  /**
   * Send selected campaign lead to the queue
   * @param values
   */
  async function handleSelectFormSubmit(values: SelectFormValues) {
    try {
      // We need to use .unwrap(); to check if error occurred
      await sendToQueue({
        id: values.campaignLeadId as number,
        newState: values.newState as CampaignLeadState
      }).unwrap();

      message.success(tSqs('addToQueueSuccess'));
    } catch (e) {
      message.error(tSqs('addToQueueError'));
    }
  }

  /**
   * Send selected campaign lead to the queue
   * @param values
   */
  async function handleEnterFormSubmit(values: EnterFormValues) {
    const { campaignLeadsId } = values;

    const regex = /^\d+(,\d+)*(,\s?\d+)*$/;

    const isValid = regex.test(campaignLeadsId);

    if (!isValid) {
      message.error(tSqs('inputFormatError'));

      return;
    }

    const ids = campaignLeadsId
      .split(/,|\s/)
      .map((num) => parseInt(num, 10))
      .filter((value, index, self) => self.indexOf(value) === index);

    const requests = ids.map(async (item) => {
      const result: { id: number; newState?: CampaignLeadState } = { id: item };

      if (!values.keepPrimaryStatus) result.newState = values.newState as CampaignLeadState;

      try {
        return await sendToQueue(result).unwrap();
      } catch (e) {
        message.error(tSqs('addToQueueNotExistedCampaignLeadError', { value: result.id }));

        throw e;
      }
    });

    await Promise.all(requests)
      .then(() => {
        message.success(tSqs('addToQueueSuccess'));
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }

  /**
   * Helper function to send campaign id to BE for the push to queue and show success/error message
   * @param values
   */
  async function handleSendWholeCampaign(values: SendWholeCampaignFormValues) {
    const { campaignId, newState } = values;

    try {
      await sendWholeCampaignToQueue({
        campaignId: Number(campaignId),
        newState: newState as CampaignLeadState
      }).unwrap();

      message.success(tSqs('pushWholeCampaignForm.addCampaignToQueueSuccess'));
    } catch (e) {
      message.error(tSqs('addToQueueError'));
    }
  }

  return (
    <div>
      <div className="py-6">
        <h2 className="mb-4 text-4xl font-extrabold">{tSqs('title')}</h2>

        <Radio.Group
          className="mb-5"
          onChange={(e) => setSendCampaignLeadType(e.target.value)}
          value={sendCampaignLeadType}
        >
          <Radio value="select">{tSqs('sendCampaignLeadTypeSelect')}</Radio>
          <Radio value="enter">{tSqs('sendCampaignLeadTypeEnter')}</Radio>
          <Radio value="wholeCampaign">{tSqs('pushWholeCampaign')}</Radio>
        </Radio.Group>

        {sendCampaignLeadType === SendCampaignLeadType.Select && (
          <ChooseCampaignLeadForm
            form={selectForm}
            isSubmitLoading={isSubmitLoading}
            onSubmit={handleSelectFormSubmit}
            statusesOptions={statusesOptions}
          />
        )}

        {sendCampaignLeadType === SendCampaignLeadType.Enter && (
          <EnterCampaignLeadIdForm
            form={enterForm}
            isSubmitLoading={isSubmitLoading}
            onSubmit={handleEnterFormSubmit}
            statusesOptions={statusesOptions}
          />
        )}

        {sendCampaignLeadType === SendCampaignLeadType.WholeCampaign && (
          <ChooseCampaignForm
            form={wholeCampaignForm}
            isSubmitLoading={isPushCampaignToSqsLoading}
            statusesOptions={statusesOptions}
            onSubmit={handleSendWholeCampaign}
          />
        )}
      </div>
    </div>
  );
}
