import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import { Form, FormInstance, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ResolveDuplicatesHeader from '../Builder/components/ResolveDuplicatesHeader/ResolveDuplicatesHeader';
import ProspectsSection from './filtersSections/ProspectsWrapper';
import { IntentLeadDataResult } from '../../redux-store/slices/intentApiSlice';
import { LeadProvider } from '../../types';
import { StreamParsedData, useFetch } from '../Builder/services/useFetch';
import { AiProspectingSearchMethods, CompanyProviders, NewsProviders } from '../../types/aiProspecting';
import { checkIsAnyFieldFilled } from './services/fieldValidationUtils';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

export enum Phases {
  Companies,
  Intent,
  Finish,
  SearchCompleted
}

interface IntentStreamError {
  message: string;
  statusCode?: number;
  type?: string;
}

type NonVacancyPhases = Record<AiProspectingSearchMethods, number>;

export interface FunnelData extends NonVacancyPhases {
  companiesProcessed: number;
  companiesWithVacancy: string[];
  companyNames: string[];
}

export interface IntentSearchResult {
  phase: string;
  leads: IntentLeadDataResult[];
  companiesCount?: number;
  companiesOpened?: number;
  companyName?: string;
  vacanciesCount?: number;
  vacanciesProcessed?: number;
  leadsCount?: number;
  leadsProcessed?: number;
  nextPageParamForCompanies?: string;
  companiesBatchIdReqParam?: number;
  searchWasTriggered?: boolean;
  foundLeadIds?: number[];
  totalCompaniesProcessed?: number;
  dontHaveCompaniesToProcess?: boolean;
  funnelData?: FunnelData;
  intentData?: null | object;
  error?: IntentStreamError;
}

export type DisplayedIntentSearchResult = IntentSearchResult | null;

export interface AiProspectingBuilderForm {
  companyProvider: CompanyProviders;
  domain: string[];
  name: string[];
  industry: string[];
  location: string[];
  size: string[];
  fundingLastRoundType: string;
  fundingLastRoundDate: Dayjs | null;
  monthlyPercentageEmployeeGrowth?: number | null;
  categoriesAndKeywords: string[];
  searchMethods: AiProspectingSearchMethods[];
  isDeepSearchEnabled: boolean;
  webQuery?: string[];
  webPeriod?: string;
  newsProvider: NewsProviders;
  newsQuery?: string[];
  newsDate?: Dayjs | null;
  newsResources?: string[];
  technologyQuery?: string[];
  hiringIntentJobTitle?: string[];
  jobPostContains?: string[];
  minPostedDate?: number | null;
  leadJobTitle: string[];
  leadExcludedJobTitle: string[];
  leadProvider: LeadProvider;
  department: string[];
  experience: string[];
  managementLevel: string[];
  searchCompaniesWithUrl: boolean;
}

export type AiProspectingField = keyof AiProspectingBuilderForm;

export const customSearchParams: (keyof IntentSearchResult)[] = [
  'nextPageParamForCompanies',
  'companiesBatchIdReqParam'
];

/**
 * Get intent fields
 * @param provider
 * @returns
 */
export function getIntentFields(provider: LeadProvider): Record<string, AiProspectingField[]> {
  const baseLeadFields: AiProspectingField[] = ['department', 'leadJobTitle', 'managementLevel'];
  // Additional fieds for some providers
  const additionalFields: Partial<Record<string, AiProspectingField[]>> = {
    [LeadProvider.Apollo]: ['leadExcludedJobTitle', 'experience'],
    [LeadProvider.RocketReach]: ['experience'],
    [LeadProvider.Swarm]: ['experience']
  };

  return {
    company: [
      'location',
      'name',
      'industry',
      'fundingLastRoundDate',
      'size',
      'fundingLastRoundType',
      'domain',
      'companySize',
      'monthlyPercentageEmployeeGrowth',
      'categoriesAndKeywords'
    ],
    leads: [...baseLeadFields, ...(additionalFields[provider] || [])]
  } as Record<string, AiProspectingField[]>;
}

const invalidUrlParams = ['null', 'undefined', 'NaN'];
const defaultFilterValues = {
  searchMethod: AiProspectingSearchMethods.Hiring,
  leadProvider: LeadProvider.RocketReach,
  isDeepSearchEnabled: false,
  searchCompaniesWithUrl: true,
  newsProvider: NewsProviders.NewsApi
};

export default function AiProspectingBuilder() {
  const { t: tProspects } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const formRef = useRef<FormInstance<AiProspectingBuilderForm>>(null);
  const [form] = Form.useForm<AiProspectingBuilderForm>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchWithCredentials, streamProcessingLoop, abortStream } = useFetch();
  const [leadsToShowInTable, setLeadsToShowInTable] = useState<DisplayedIntentSearchResult>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [companiesSearchLimitReacted, setCompaniesSearchLimitReached] = useState(false);
  const params: Record<string, string> = Object.fromEntries(searchParams);
  const fieldValues = form.getFieldsValue();
  const initialValues: AiProspectingBuilderForm = {
    companyProvider: CompanyProviders.CoreSignal,
    domain: [],
    name: [],
    industry: [],
    location: [],
    size: [],
    fundingLastRoundType: '',
    fundingLastRoundDate: null,
    monthlyPercentageEmployeeGrowth: null,
    categoriesAndKeywords: [],
    hiringIntentJobTitle: [],
    jobPostContains: [],
    minPostedDate: null,
    leadJobTitle: [],
    leadExcludedJobTitle: [],
    department: [],
    experience: [],
    searchMethods: [defaultFilterValues.searchMethod],
    isDeepSearchEnabled: defaultFilterValues.isDeepSearchEnabled,
    webQuery: [],
    webPeriod: '',
    newsProvider: defaultFilterValues.newsProvider,
    newsQuery: [],
    newsDate: null,
    newsResources: [],
    managementLevel: [],
    leadProvider: defaultFilterValues.leadProvider,
    technologyQuery: [],
    searchCompaniesWithUrl: defaultFilterValues.searchCompaniesWithUrl
  };
  // Min time for animated step
  const minPhaseDuration = 1500;
  const maxCompaniesToProcessLimit = 1000000;
  const [currentPhase, setCurrentPhase] = useState(Phases.Companies);

  const shouldContinueSearch = leadsToShowInTable?.searchWasTriggered || leadsToShowInTable?.nextPageParamForCompanies;
  const phaseCounts: FunnelData = shouldContinueSearch
    ? {
        news: leadsToShowInTable?.funnelData?.news ?? 0,
        web: leadsToShowInTable?.funnelData?.web ?? 0,
        hiring: leadsToShowInTable?.funnelData?.hiring ?? 0,
        technology: leadsToShowInTable?.funnelData?.technology ?? 0,
        companiesProcessed: leadsToShowInTable?.funnelData?.companiesProcessed ?? 0,
        companiesWithVacancy: leadsToShowInTable?.funnelData?.companiesWithVacancy ?? [],
        companyNames: leadsToShowInTable?.funnelData?.companyNames ?? []
      }
    : {
        news: 0,
        web: 0,
        hiring: 0,
        technology: 0,
        companiesProcessed: 0,
        companiesWithVacancy: [],
        companyNames: []
      };

  /*  Updates form and table fields based on URL parameters on the first render. */
  useEffect(() => {
    if (!Object.keys(params).length) return;
    const transformedQuery: Partial<AiProspectingBuilderForm> = transformQueryParamsToFormFields(
      params,
      customSearchParams
    );

    // Set up all form fields based on the URL parameters
    form.setFieldsValue({ ...transformedQuery });
    setLeadsToShowInTable((prev) => {
      const currentLeads = prev || {
        phase: '',
        leads: []
      };
      // Set up IntentSearchResult additional fields based on the URL parameters
      const additionalFields = transformQueryParamsToAdditionalIntentSearchFields(params, customSearchParams);

      return {
        ...currentLeads,
        ...additionalFields
      };
    });
  }, []);

  /* Sets default values in URL parameters and removes invalid ones on the first render */
  useEffect(() => {
    const updatedParams = { ...params };

    // These values are updated to the default if they are not present in the URL.
    if (!updatedParams?.searchMethods) updatedParams.searchMethods = JSON.stringify([defaultFilterValues.searchMethod]);

    if (!updatedParams?.isDeepSearchEnabled)
      updatedParams.isDeepSearchEnabled = JSON.stringify(defaultFilterValues.isDeepSearchEnabled);

    if (!updatedParams?.leadProvider) updatedParams.leadProvider = defaultFilterValues.leadProvider;

    // Removing invalid parameters from the URL.
    if (updatedParams?.nextPageParamForCompanies && invalidUrlParams.includes(updatedParams?.nextPageParamForCompanies))
      delete updatedParams.nextPageParamForCompanies;

    if (updatedParams?.companiesBatchIdReqParam && invalidUrlParams.includes(updatedParams?.companiesBatchIdReqParam))
      delete updatedParams.companiesBatchIdReqParam;

    setSearchParams(updatedParams);
  }, []);

  /**
   * If stream not loading - show completed funned data
   */
  useEffect(() => {
    !isLoading && setCurrentPhase(Phases.SearchCompleted);
  }, [isLoading]);

  /**
   * Transforms URL query parameters into form fields for AI Prospecting Builder.
   * @param  urlParams - An object containing URL query parameters.
   * @param arrayOfCustomParamsToAvoid - An array of keys to exclude from transformation.
   * @returns An object with transformed form fields.
   */
  function transformQueryParamsToFormFields(
    urlParams: Record<string, string>,
    arrayOfCustomParamsToAvoid: (keyof IntentSearchResult)[]
  ): Partial<AiProspectingBuilderForm> {
    return Object.keys(urlParams).reduce((acc, key) => {
      if (!arrayOfCustomParamsToAvoid.includes(key as keyof IntentSearchResult)) {
        acc[key as AiProspectingField] =
          urlParams[key][0] === '[' || key === 'isDeepSearchEnabled' ? JSON.parse(urlParams[key]) : urlParams[key];
      }

      return acc;
    }, {} as Partial<AiProspectingBuilderForm>);
  }

  /**
   * Transforms specific URL query parameters into additional intent search fields.
   * @param  urlParams - An object containing URL query parameters.
   * @param  additionalFields - An array of keys for additional fields to include.
   * @returns An object with transformed additional intent search fields.
   */
  function transformQueryParamsToAdditionalIntentSearchFields(
    urlParams: Record<string, string>,
    additionalFields: (keyof IntentSearchResult)[]
  ) {
    return additionalFields.reduce((acc: IntentSearchResult, field: keyof IntentSearchResult) => {
      const value = urlParams[field];

      if (value && !invalidUrlParams.includes(value)) {
        switch (field) {
          case 'nextPageParamForCompanies':
            acc[field] = value;
            break;
          case 'companiesBatchIdReqParam':
            acc[field] = Number(value);
            break;
        }
      }

      return acc;
    }, {} as IntentSearchResult);
  }

  /**
   * Increment funnel values for current phase
   * @param phase - The phase to increment the count for.
   * @param info - Some stream info
   */
  function incrementPhaseCount(
    phase: string,
    info: { intentData?: null | object; companyName?: string; companiesCount?: number }
  ) {
    if (phase === 'vacancies') {
      // Increment companiesProcessed and companiesWithVacancy
      phaseCounts.companiesProcessed += info?.companiesCount || 0;
      // If company already exists - not add it
      if (info?.companyName && !phaseCounts.companiesWithVacancy.includes(info.companyName))
        phaseCounts.companiesWithVacancy.push(info.companyName);
    }

    if (phase in phaseCounts && !!info?.intentData) phaseCounts[phase as keyof NonVacancyPhases] += 1;

    if (info?.companyName) phaseCounts.companyNames.push(info.companyName);
  }

  /**
   * Update search params that are not the form values
   * @param nextPageParamForCompanies
   * @param companiesBatchIdReqParam
   */
  function updateSearchParams(nextPageParamForCompanies?: string | null, companiesBatchIdReqParam?: number | null) {
    const updatedParams: Record<string, string> = { ...params };

    if (nextPageParamForCompanies && !invalidUrlParams.includes(nextPageParamForCompanies)) {
      updatedParams.nextPageParamForCompanies = String(nextPageParamForCompanies).trim();
    }

    if (typeof companiesBatchIdReqParam === 'number' && !isNaN(companiesBatchIdReqParam)) {
      updatedParams.companiesBatchIdReqParam = String(companiesBatchIdReqParam);
    }

    setSearchParams(updatedParams);
  }

  /**
   * Handle received intent search result stream and saving values in the state
   * @param value
   */
  function handleLeadsWithIntent(value: StreamParsedData) {
    const {
      phase,
      data: {
        companiesCount,
        vacanciesCount,
        vacanciesProcessed,
        companiesOpened,
        companyName,
        leadsCount,
        leadsProcessed,
        lead,
        nextPageParamForCompanies,
        companiesBatchIdReqParam,
        totalCompaniesProcessed,
        dontHaveCompaniesToProcess,
        intentData,
        error
      }
    } = value as { phase: string; data: IntentSearchResult & { lead: IntentLeadDataResult } };
    const isInitialLoad = companiesCount === 0 || leadsCount === 0;

    if (error) message.error(error?.message);

    if (isInitialLoad) {
      setLeadsToShowInTable((prev) => {
        const currentLeads = prev || {
          phase: '',
          leads: []
        };
        const updatedNextPageParamForCompanies = nextPageParamForCompanies ?? currentLeads?.nextPageParamForCompanies;
        const updatedTotalCompaniesProcessed = totalCompaniesProcessed ?? currentLeads?.totalCompaniesProcessed ?? 0;
        const cantProcessMoreCompaniesCondition = updatedTotalCompaniesProcessed >= maxCompaniesToProcessLimit;

        if (dontHaveCompaniesToProcess || cantProcessMoreCompaniesCondition) {
          setCompaniesSearchLimitReached(true);
        }

        return {
          ...currentLeads,
          phase,
          companiesCount: companiesCount ?? currentLeads.companiesCount,
          totalCompaniesProcessed: updatedTotalCompaniesProcessed,
          vacanciesCount: vacanciesCount ?? currentLeads.vacanciesCount,
          leadsCount: currentLeads?.leads.length ?? 0,
          leads: currentLeads?.leads ?? [],
          nextPageParamForCompanies: updatedNextPageParamForCompanies,
          companiesBatchIdReqParam: companiesBatchIdReqParam ?? currentLeads?.companiesBatchIdReqParam,
          funnelData: currentLeads?.funnelData ?? phaseCounts
        };
      });

      return;
    }

    updateSearchParams(nextPageParamForCompanies, companiesBatchIdReqParam);
    // If intent search block returned data - count it as successful
    incrementPhaseCount(phase, { intentData, companiesCount, companyName });
    setLeadsToShowInTable((prev) => {
      const currentLeads = prev || {
        phase: '',
        leads: []
      };
      const isLeadPresent = currentLeads.leads.some((currentLead) => currentLead.id === lead?.id);
      const updatedLeads = lead && !isLeadPresent ? [...currentLeads.leads, lead] : currentLeads.leads;
      const updatedNextPageParamForCompanies = nextPageParamForCompanies ?? currentLeads?.nextPageParamForCompanies;
      const updatedTotalCompaniesProcessed = totalCompaniesProcessed ?? currentLeads?.totalCompaniesProcessed;
      const cantProcessMoreCompaniesCondition = (updatedTotalCompaniesProcessed ?? 0) >= maxCompaniesToProcessLimit;

      if (dontHaveCompaniesToProcess || cantProcessMoreCompaniesCondition) {
        setCompaniesSearchLimitReached(true);
      }

      return {
        ...currentLeads,
        phase: phase ?? currentLeads.phase,
        companiesCount: companiesCount ?? currentLeads.companiesCount,
        companiesOpened: companiesOpened ?? currentLeads.companiesOpened,
        totalCompaniesProcessed: updatedTotalCompaniesProcessed,
        vacanciesProcessed: vacanciesProcessed ?? currentLeads.vacanciesProcessed,
        vacanciesCount: vacanciesCount ?? currentLeads.vacanciesCount,
        leadsCount: leadsCount ?? currentLeads.leadsCount,
        leadsProcessed: leadsProcessed ?? currentLeads.leadsProcessed,
        leads: updatedLeads,
        nextPageParamForCompanies: updatedNextPageParamForCompanies,
        companiesBatchIdReqParam: companiesBatchIdReqParam ?? currentLeads.companiesBatchIdReqParam,
        searchWasTriggered: currentLeads.searchWasTriggered ?? false,
        funnelData: phaseCounts
      };
    });
  }

  /**
   * Collect current lead ids to avoid duplications
   */
  function collectFoundLeadIds() {
    return leadsToShowInTable?.leads.map((lead) => lead.id) ?? [];
  }

  /**
   * Handle search click, create filter request and send request to get leads
   */
  async function handleSearchClick(values: AiProspectingBuilderForm) {
    const {
      companyProvider,
      domain,
      name,
      location,
      size,
      fundingLastRoundType,
      fundingLastRoundDate,
      categoriesAndKeywords,
      monthlyPercentageEmployeeGrowth,
      hiringIntentJobTitle,
      jobPostContains,
      minPostedDate,
      industry,
      searchMethods,
      isDeepSearchEnabled,
      webQuery,
      webPeriod,
      newsProvider,
      newsQuery,
      newsDate,
      newsResources,
      technologyQuery,
      leadJobTitle,
      leadExcludedJobTitle,
      department,
      experience,
      managementLevel,
      leadProvider,
      searchCompaniesWithUrl
    } = values;
    let numberOfProcessedVacancies = leadsToShowInTable?.vacanciesProcessed ?? 0;
    let totalCompaniesProcessed = leadsToShowInTable?.totalCompaniesProcessed ?? 0;
    let companiesOpened = leadsToShowInTable?.companiesOpened ?? 0;
    let foundLeadIds = collectFoundLeadIds();
    const intentFields = getIntentFields(leadProvider);
    const showLeadsCommonValidationError = !checkIsAnyFieldFilled(intentFields.leads, fieldValues);
    const showCompanyCommonValidationError = !checkIsAnyFieldFilled(intentFields.company, fieldValues);

    try {
      setIsFormSubmitted(true);

      if (!showLeadsCommonValidationError && !showCompanyCommonValidationError) {
        setIsLoading(true);
        setCompaniesSearchLimitReached(false);
        setCurrentPhase(Phases.Companies);
        if (!leadsToShowInTable?.searchWasTriggered) {
          setLeadsToShowInTable({
            phase: '',
            leads: [],
            searchWasTriggered: true
          });
          numberOfProcessedVacancies = 0;
          totalCompaniesProcessed = 0;
          companiesOpened = 0;
          foundLeadIds = [];
        }
        const nextPageReqParam = leadsToShowInTable?.nextPageParamForCompanies ?? null;
        const companiesBatchId = leadsToShowInTable?.companiesBatchIdReqParam ?? null;
        const filtersRequest = {
          companyProvider,
          domain,
          name: name || [],
          industry: industry || [],
          location: location || [],
          size: size || [],
          fundingLastRoundType,
          fundingLastRoundDate,
          monthlyPercentageEmployeeGrowth: Number(monthlyPercentageEmployeeGrowth),
          categoriesAndKeywords,
          hiringIntentJobTitle,
          jobPostContains,
          minPostedDate: Number(minPostedDate),
          searchMethods,
          isDeepSearchEnabled,
          webQuery,
          webPeriod,
          newsProvider,
          newsQuery,
          newsDate,
          newsResources,
          technologyQuery,
          leadJobTitle,
          leadExcludedJobTitle,
          department,
          experience,
          managementLevel,
          leadProvider: leadProvider || LeadProvider.RocketReach,
          nextPageParamForCompanies: nextPageReqParam,
          companiesBatchIdReqParam: companiesBatchId,
          foundLeadIds,
          numberOfProcessedVacancies,
          totalCompaniesProcessed,
          companiesOpened,
          searchCompaniesWithUrl: Boolean(searchCompaniesWithUrl)
        } as AiProspectingBuilderForm;

        // In 1500 ms show second phase
        setTimeout(() => {
          setCurrentPhase(Phases.Intent);
        }, minPhaseDuration);
        const stream = await fetchWithCredentials('intent/search-leads', filtersRequest);

        await streamProcessingLoop(stream, handleLeadsWithIntent);
        // After all requests show last phase
        setCurrentPhase(Phases.Finish);
        // We need to specifically slow down the loading so we can show the last phase for 1500ms
        setTimeout(() => {
          setIsLoading(false);
        }, minPhaseDuration);
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        message.success(tProspects('streamCanceled'));
      } else {
        abortStream();
        setIsFormSubmitted(false);
        message.error(tProspects('searchError'));
      }
      setIsLoading(false);
      setCurrentPhase(Phases.Companies);
    }
  }

  return (
    <div className="py-6" data-testid="ai-prospecting-builder">
      <h2 className="text-4xl font-semibold mb-5">{tProspects('aiProspecting')}</h2>
      <ResolveDuplicatesHeader type="info" message={tProspects('infoIntentHeader')} />
      <Form
        scrollToFirstError
        ref={formRef}
        form={form}
        layout="vertical"
        validateTrigger="onSubmit"
        initialValues={initialValues}
        onFinish={handleSearchClick}
        requiredMark={false}
      >
        <ProspectsSection
          form={form}
          cancelStream={abortStream}
          currentPhase={currentPhase}
          isFormSubmitted={isFormSubmitted}
          leadsToShowInTable={leadsToShowInTable}
          setLeadsToShowInTable={setLeadsToShowInTable}
          isLoading={isLoading}
          companiesSearchLimitReacted={companiesSearchLimitReacted}
        />
      </Form>
    </div>
  );
}
