import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import ResolveDuplicatesHeader from '../../Builder/components/ResolveDuplicatesHeader/ResolveDuplicatesHeader';
import Hint from '../components/Hint';
import MultiSelect from '../components/MultiSelect';
import { InputTags } from '../components/InputTags';
import { SectionsProps } from './ProspectsWrapper';
import { isValidDomain } from '../services/fieldValidationUtils';
import { useFetchStaticFileQuery } from '../../../redux-store';
import { transformEnumToOptions, transformSuggestionsToOptions } from '../../../services/transformOption';
import { OceanIoCompanySize } from '../../../types/aiProspecting';

export default function OceanIoCompanyForm({
  handleChange,
  disabled
}: Pick<SectionsProps, 'handleChange' | 'disabled'>) {
  const { data: countries, isLoading: isLoadingCounties } = useFetchStaticFileQuery({ file: '/countries.json' });
  const { data: industries, isLoading: isLoadingIndustries } = useFetchStaticFileQuery({
    file: '/oi-industries.json'
  });

  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });

  return (
    <div data-testid="ocean-io-form" className="mt-5">
      <ResolveDuplicatesHeader message={t('baseFilters.competitorsDomainInfo')} type="info" />
      <Form.Item
        name="domain"
        data-testid="company-domains-field"
        label={
          <div className="flex items-center gap-1">
            <span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.competitorsDomain')}</span>
            <Hint content={t('inputTagsTooltip')}>
              <InfoCircleOutlined className=" cursor-pointer text-[var(--button-primary)]" />
            </Hint>
          </div>
        }
        className="!mb-0"
        rules={[
          {
            required: true,
            message: t('form.validations.domain')
          },
          {
            validator: (_, value: string[]) => {
              if (!value || value.length === 0) return Promise.resolve();

              const invalidDomains = value.filter((domain) => !isValidDomain(domain.trim()));

              if (!invalidDomains.length) return Promise.resolve();

              if (invalidDomains.length === 1) {
                return Promise.reject(
                  new Error(t(`form.validations.singleDomainValidation`, { domain: invalidDomains.join(', ') }))
                );
              }

              return Promise.reject(
                new Error(t(`form.validations.multipleDomainValidation`, { domains: invalidDomains.join(', ') }))
              );
            }
          }
        ]}
      >
        <InputTags
          placeholder={t('baseFilters.placeholders.competitorsDomain')}
          disabled={disabled}
          callback={(value) => {
            handleChange('domain', value);
          }}
        />
      </Form.Item>
      <div className="mt-2.5 grid gap-2.5 md:grid-cols-2">
        <Form.Item
          data-testid="company-location-field"
          name="location"
          label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.location')}</span>}
          className="!mb-0 "
        >
          <MultiSelect
            showSearch
            id="location"
            mode="tags"
            disabled={disabled}
            isLoading={isLoadingCounties}
            placeholder={t('baseFilters.placeholders.location')}
            options={transformSuggestionsToOptions(countries)}
            setSelected={(value) => handleChange('location', value)}
          />
        </Form.Item>
        <Form.Item
          data-testid="company-size-field"
          name="size"
          label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.size')}</span>}
          className="!mb-0"
        >
          <MultiSelect
            id="size"
            mode="multiple"
            disabled={disabled}
            placeholder={t('baseFilters.placeholders.size')}
            options={transformEnumToOptions({
              value: OceanIoCompanySize,
              key: 'companySize',
              baseLabel: 'baseFilters',
              t
            })}
            setSelected={(value) => handleChange('size', value)}
          />
        </Form.Item>
        <Form.Item
          data-testid="industry-field"
          name="industry"
          label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.industry')}</span>}
          className="!mb-0"
        >
          <MultiSelect
            virtual
            showSearch
            id="industry"
            mode="tags"
            disabled={disabled}
            placeholder={t('baseFilters.placeholders.industry')}
            isLoading={isLoadingIndustries}
            options={transformSuggestionsToOptions(industries)}
            setSelected={(value) => handleChange('industry', value)}
          />
        </Form.Item>
      </div>
    </div>
  );
}
