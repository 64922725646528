import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import ResolveDuplicatesHeader from '../../Builder/components/ResolveDuplicatesHeader/ResolveDuplicatesHeader';
import CoreSignalCompanyForm from './CoreSignalCompanyForm';
import OceanIoCompanyForm from './OceanIoCompanyForm';
import { CompanyProviders } from '../../../types/aiProspecting';
import { useGetMeQuery } from '../../../redux-store';
import { SectionsProps } from './ProspectsWrapper';
import { Features } from '../../../types';

import { ReactComponent as BasicFiltersSectionIcon } from '../images/basicFiltersIcon.svg';

const companyProviders = [CompanyProviders.CoreSignal, CompanyProviders.OceanIo];

export default function BasicFiltersSection({
  form,
  showCommonValidationError,
  searchInputHandle,
  handleChange,
  disabled
}: SectionsProps) {
  const { currentData: user } = useGetMeQuery(null);
  const hasOceanIoIntegrationFeatureEnabled = user?.hasFeature(user, Features.OceanIoIntegration);
  const hasWebNewsSearchFeatureEnabled = user?.hasFeature(user, Features.WebNewsIntentSearch);
  const selectedCompanyProvider = Form.useWatch('companyProvider', { form });
  const searchCompaniesWithUrl = Form.useWatch('searchCompaniesWithUrl', { form, preserve: true });
  const isOceanIoCompanyProvider = useMemo(
    () =>
      hasOceanIoIntegrationFeatureEnabled &&
      hasWebNewsSearchFeatureEnabled &&
      selectedCompanyProvider === CompanyProviders.OceanIo,
    [hasOceanIoIntegrationFeatureEnabled, hasWebNewsSearchFeatureEnabled, selectedCompanyProvider]
  );
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });

  /**
   * Handle company website checkbox to search only companies with website url
   * @param e
   */
  function handleCompanyWebsiteCheckbox(e: CheckboxChangeEvent) {
    handleChange('searchCompaniesWithUrl', e.target.checked);
  }

  return (
    <div className="mb-[30px]" data-testid="basic-filters-section">
      <div className="flex flex-row items-center text-xl font-medium mb-4 space-x-2">
        <BasicFiltersSectionIcon />
        <span>{t('baseFilters.blockName')}</span>
      </div>
      {showCommonValidationError && (
        <div data-testid="basic-filters-warning">
          <ResolveDuplicatesHeader message={t('commonValidationAlert')} type="error" />
        </div>
      )}
      <div className="mt-5 bg-white rounded-lg p-5 border border-solid border-[var(--input-border)]">
        {hasOceanIoIntegrationFeatureEnabled && (
          <Form.Item
            name="companyProvider"
            label={<span className="font-bold">{t('baseFilters.labels.searchType')}</span>}
            className="mb-2.5 w-full md:w-[50%]"
          >
            <Select
              disabled={disabled}
              className="h-[48px]"
              onChange={(value) => {
                handleChange('companyProvider', value);
              }}
              data-testid="company-provider-select"
            >
              {companyProviders?.map((provider) => (
                <Select.Option key={provider} value={provider}>
                  <span>{t(`baseFilters.labels.${provider}`)}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {isOceanIoCompanyProvider ? (
          <OceanIoCompanyForm handleChange={handleChange} disabled={disabled} />
        ) : (
          <CoreSignalCompanyForm
            form={form}
            searchInputHandle={searchInputHandle}
            handleChange={handleChange}
            disabled={disabled}
          />
        )}
      </div>
      {!isOceanIoCompanyProvider && (
        <div className="mt-2.5">
          <Form.Item name="searchCompaniesWithUrl" className="!mb-0">
            <Checkbox
              data-testid="include-company-website-url-checkbox"
              checked={!!searchCompaniesWithUrl}
              onChange={handleCompanyWebsiteCheckbox}
              name="companyIncludeCompanyUrl"
            >
              {t('baseFilters.placeholders.searchWithCompaniesUrl')}
            </Checkbox>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
