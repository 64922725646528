import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import MultiSelect from '../components/MultiSelect';
import DatePicker from '../components/DatePicker';
import Hint from '../components/Hint';
import { CompanyFunding, CoresSignalCompanySize } from '../../../types/aiProspecting';
import { SectionsProps } from './ProspectsWrapper';
import { transformEnumToOptions, transformSuggestionsToOptions } from '../../../services/transformOption';
import { InputTags } from '../components/InputTags';
import { useFetchStaticFileQuery } from '../../../redux-store';

export default function CoreSignalCompanyForm({
  form,
  searchInputHandle,
  handleChange,
  disabled
}: Omit<SectionsProps, 'search'>) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const { data: countries, isLoading: isLoadingCounties } = useFetchStaticFileQuery({ file: '/countries.json' });
  const { data: industries, isLoading: isLoadingIndustries } = useFetchStaticFileQuery({ file: '/cs-industries.json' });
  const monthlyPercentageOptions = [
    { label: '5%', value: '5' },
    { label: '10%', value: '10' },
    { label: '15%', value: '15' },
    { label: '20%', value: '20' },
    { label: '50%', value: '50' }
  ];
  const monthlyPercentageEmployeeGrowth = Form.useWatch('monthlyPercentageEmployeeGrowth', { form, preserve: true });

  return (
    <div data-testid="core-signal-form" className=" grid grid-cols-1 md:grid-cols-2 gap-2.5 grid-flow-dense">
      <Form.Item
        data-testid="company-name-field"
        name="name"
        label={
          <div className="flex items-center gap-1">
            <span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.name')}</span>
            <Hint content={t('inputTagsTooltip')}>
              <InfoCircleOutlined className=" cursor-pointer text-[var(--button-primary)]" />
            </Hint>
          </div>
        }
        className="!mb-0"
      >
        <InputTags
          placeholder={t('baseFilters.placeholders.name')}
          disabled={disabled}
          callback={(value) => {
            handleChange('name', value);
          }}
        />
      </Form.Item>
      <Form.Item
        data-testid="industry-field"
        name="industry"
        label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.industry')}</span>}
        className="!mb-0 md:col-start-1"
      >
        <MultiSelect
          showSearch
          id="industry"
          mode="tags"
          virtual
          disabled={disabled}
          placeholder={t('baseFilters.placeholders.industry')}
          isLoading={isLoadingIndustries}
          options={transformSuggestionsToOptions(industries)}
          setSelected={(value) => handleChange('industry', value)}
        />
      </Form.Item>
      <Form.Item
        data-testid="company-size-field"
        name="size"
        label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.size')}</span>}
        className="!mb-0"
      >
        <MultiSelect
          id="size"
          mode="multiple"
          disabled={disabled}
          placeholder={t('baseFilters.placeholders.size')}
          options={transformEnumToOptions({
            value: CoresSignalCompanySize,
            key: 'companySize',
            baseLabel: 'baseFilters',
            t
          })}
          setSelected={(value) => handleChange('size', value)}
        />
      </Form.Item>
      <Form.Item
        data-testid="hiring-intent-location"
        name="location"
        label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.location')}</span>}
        className="!mb-0 md:col-start-1 "
      >
        <MultiSelect
          showSearch
          id="location"
          mode="tags"
          disabled={disabled}
          isLoading={isLoadingCounties}
          placeholder={t('baseFilters.placeholders.location')}
          options={transformSuggestionsToOptions(countries)}
          setSelected={(value) => handleChange('location', value)}
          setSearch={(value) => searchInputHandle(value, 'location')}
        />
      </Form.Item>
      <Form.Item
        data-testid="funding-last-round-type-field"
        name="fundingLastRoundType"
        label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.fundingLastRoundType')}</span>}
        className="!mb-0 md:col-start-2"
      >
        <MultiSelect
          id="fundingLastRoundType"
          disabled={disabled}
          placeholder={t('baseFilters.placeholders.fundingLastRoundType')}
          options={transformEnumToOptions({
            value: CompanyFunding,
            key: 'companyFunding',
            baseLabel: 'baseFilters',
            t
          })}
          setSelected={(value) => handleChange('fundingLastRoundType', value)}
        />
      </Form.Item>
      <Form.Item
        data-testid="funding-last-round-data-field"
        name="fundingLastRoundDate"
        label={<span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.fundingLastRoundDate')}</span>}
        className="!mb-0"
      >
        <DatePicker
          endDate={dayjs()}
          disabled={disabled}
          placeholder={t('baseFilters.placeholders.fundingLastRoundDate')}
          onChange={(_, value) => handleChange('fundingLastRoundDate', value)}
        />
      </Form.Item>
      <Form.Item
        data-testid="categories-and-keywords-field"
        name="categoriesAndKeywords"
        label={
          <div className="flex items-center gap-1">
            <span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.categoriesAndKeywords')}</span>
            <Hint content={t('inputTagsTooltip')}>
              <InfoCircleOutlined className=" cursor-pointer text-[var(--button-primary)]" />
            </Hint>
          </div>
        }
        className="!mb-0"
      >
        <InputTags
          placeholder={t('baseFilters.placeholders.categoriesAndKeywords')}
          disabled={disabled}
          callback={(value) => handleChange('categoriesAndKeywords', value)}
        />
      </Form.Item>
      <Form.Item
        data-testid="monthly-percentage-employee-growth-field"
        name="monthlyPercentageEmployeeGrowth"
        label={
          <div className="flex items-center gap-1">
            <span style={{ fontWeight: 'bold' }}>{t('baseFilters.labels.monthlyPercentageEmployeeGrowth')}</span>
            <Hint content={t('monthlyPercentageEmployeeGrowthTooltip')}>
              <InfoCircleOutlined className=" cursor-pointer text-[var(--button-primary)]" />
            </Hint>
          </div>
        }
        className="!mb-0"
      >
        <MultiSelect
          id="monthlyPercentageEmployeeGrowth"
          disabled={disabled}
          placeholder={t('baseFilters.placeholders.monthlyPercentageEmployeeGrowth')}
          options={monthlyPercentageOptions}
          value={monthlyPercentageEmployeeGrowth ? `${monthlyPercentageEmployeeGrowth}%` : undefined}
          setSelected={(value) => handleChange('monthlyPercentageEmployeeGrowth', value)}
        />
      </Form.Item>
    </div>
  );
}
