import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

import AccountResearchForm from '../AccountResearchForm';
import IntentLeadsTable from '../IntentLeadsTable';
import { AiProspectingBuilderForm, DisplayedIntentSearchResult } from '../AiProspectingBuilder';

type SearchAiProspectingInput = Record<
  keyof Pick<
    AiProspectingBuilderForm,
    'industry' | 'location' | 'hiringIntentJobTitle' | 'leadJobTitle' | 'leadExcludedJobTitle' | 'technologyQuery'
  >,
  string
>;

export interface SectionsProps {
  form: FormInstance<AiProspectingBuilderForm>;
  showCommonValidationError?: boolean;
  search: SearchAiProspectingInput;
  searchInputHandle: (value: string, label: string) => void;
  handleChange: (fieldName: string, value: unknown) => void;
  isSelected?: boolean;
  disabled: boolean;
}

interface ProspectsWrapperProps {
  leadsToShowInTable: DisplayedIntentSearchResult;
  setLeadsToShowInTable: (
    values: DisplayedIntentSearchResult | ((prevValues: DisplayedIntentSearchResult) => DisplayedIntentSearchResult)
  ) => void;
  form: FormInstance<AiProspectingBuilderForm>;
  currentPhase: number;
  isLoading: boolean;
  isFormSubmitted: boolean;
  cancelStream: () => void;
  companiesSearchLimitReacted: boolean;
}

export default function ProspectsWrapper(props: ProspectsWrapperProps) {
  const {
    form,
    leadsToShowInTable,
    setLeadsToShowInTable,
    isLoading,
    isFormSubmitted,
    currentPhase,
    cancelStream,
    companiesSearchLimitReacted
  } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const leads = leadsToShowInTable?.leads?.length;
  const hasLeads = leads && leads > 0;
  const isLeadsNotFound = leadsToShowInTable?.leadsCount === 0;
  const isShowTable = hasLeads || isLeadsNotFound;

  return (
    <section className="mt-5 p-5 bg-white border border-[var(--input-border)] rounded-[10px]">
      <div className="text-xl font-medium mb-5">{t('accountResearch')}</div>
      <AccountResearchForm
        isLoading={isLoading}
        isFormSubmitted={isFormSubmitted}
        currentPhase={currentPhase}
        leadsToShowInTable={leadsToShowInTable}
        setLeadsToShowInTable={setLeadsToShowInTable}
        form={form}
        cancelStream={cancelStream}
        companiesSearchLimitReacted={companiesSearchLimitReacted}
      />
      {isShowTable && <IntentLeadsTable leadsToShowInTable={leadsToShowInTable} form={form} />}
    </section>
  );
}
