import { getApiUrl } from '../services/getUrl';

export const baseUrl = getApiUrl();

export type DefaultResponse<T> = {
  count: number;
  page: number;
  pageCount: number;
  total: number;
  data: T;
};

export const ProviderTags = {
  Leads: 'Leads',
  Campaigns: 'Campaigns',
  CampaignGeneralDetails: 'CampaignGeneralDetails',
  CampaignLeads: 'CampaignLeads',
  CampaignEvents: 'CampaignEvents',
  Features: 'Features',
  Users: 'Users',
  Persona: 'Persona',
  Company: 'Company',
  Me: 'Me',
  Csrf: 'Csrf',
  LeadsDuplications: 'LeadsDuplications',
  Feedback: 'Feedback',
  PersonaQuestionnaireAnswers: 'PersonaQuestionnaireAnswers',
  SuppressionList: 'SuppressionList',
  LeadAdditionalInformation: 'LeadAdditionalInformation',
  WarmUpStatistic: 'WarmUpStatistic',
  CampaignLead: 'CampaignLead'
};

export const REFRESH_INTERVAL = 60000; // 1 minute
export const LEADS_DUPLICATIONS_REFRESH_INTERVAL = 300000; // 5 minutes
