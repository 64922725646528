import { apiSlice } from './apiSlice';

export const autocompleteApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchStaticFile: builder.query<string[], { file: string }>({
      query: ({ file }) => ({
        url: file.startsWith('/') ? `${window.location.origin}${file}` : file
      })
    })
  })
});

export const { useFetchStaticFileQuery } = autocompleteApi;
