import classNames from 'classnames';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  LeadProviderSuggestionsFields,
  useGetLeadProviderFieldSuggestionsQuery,
  useGetMeQuery
} from '../../../redux-store';
import { Features } from '../../../types';
import { MinPostedPeriod } from '../../../types/aiProspecting';
import { transformEnumToOptions, transformSuggestionsToOptions } from '../../../services/transformOption';
import MultiSelect from '../components/MultiSelect';
import { SectionsProps } from './ProspectsWrapper';

import { ReactComponent as HiringIntentSectionIcon } from '../images/hiringIntentIcon.svg';

export default function HiringIntentSection({
  form,
  isSelected,
  search,
  searchInputHandle,
  handleChange,
  disabled
}: SectionsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const { currentData: user } = useGetMeQuery(null);
  const hasWebNewsSearchFeatureEnabled = user?.hasFeature(user, Features.WebNewsIntentSearch);
  const leadProvider = Form.useWatch('leadProvider', { form });
  const { currentData: jobTitles, isFetching: isJobTitleLoading } = useGetLeadProviderFieldSuggestionsQuery(
    { field: LeadProviderSuggestionsFields.CurrentTitle, input: search?.hiringIntentJobTitle },
    { skip: !search?.hiringIntentJobTitle?.trim() }
  );

  return (
    <div className="flex flex-col h-full" data-testid="hiring-intent-section">
      <div className="flex flex-row items-center text-xl font-medium mb-4 space-x-2">
        <HiringIntentSectionIcon />
        <span>{t('hiringIntent.blockName')}</span>
      </div>
      <div
        className={classNames('p-5 h-full bg-white rounded-[5px] border border-solid flex flex-col gap-5', {
          'border-[1.5px] border-[var(--button-primary)]': isSelected,
          'hover:border-[var(--tertiary)] hover:shadow-secondary': !isSelected && !disabled
        })}
      >
        <div>
          <b>{t('hiringIntent.title')}</b>
          <div className="my-2.5 text-[var(--placeholder-secondary)]">{t('hiringIntent.subTitle')}</div>
        </div>
        <Form.Item
          name="hiringIntentJobTitle"
          label={<span style={{ fontWeight: 'bold' }}>{t('hiringIntent.labels.hiringIntentJobTitle')}</span>}
          data-testid="hiring-intent-job-title"
          className="!mb-0"
          rules={[
            {
              required: isSelected || !hasWebNewsSearchFeatureEnabled,
              message: t('form.validations.hiringIntentJobTitle')
            }
          ]}
        >
          <MultiSelect
            showSearch
            id="hiringIntentJobTitle"
            disabled={disabled}
            mode="tags"
            placeholder={t('hiringIntent.placeholders.hiringIntentJobTitle')}
            isLoading={isJobTitleLoading}
            options={transformSuggestionsToOptions(jobTitles, leadProvider)}
            setSearch={(value) => searchInputHandle(value, 'hiringIntentJobTitle')}
            setSelected={(value) => handleChange('hiringIntentJobTitle', value)}
          />
        </Form.Item>
        <Form.Item
          name="jobPostContains"
          label={<span style={{ fontWeight: 'bold' }}>{t('hiringIntent.labels.jobPostContains')}</span>}
          className="!mb-0"
        >
          <MultiSelect
            showSearch
            id="jobPostContains"
            disabled={disabled}
            placeholder={t('hiringIntent.placeholders.jobPostContains')}
            mode="tags"
            setSelected={(value) => handleChange('jobPostContains', value)}
          />
        </Form.Item>
        <Form.Item
          name="minPostedDate"
          label={<span style={{ fontWeight: 'bold' }}>{t('hiringIntent.labels.minPostedDate')}</span>}
          className="!mb-0"
          rules={[{ required: isSelected, message: t('form.validations.minPostedDate') }]}
        >
          <MultiSelect
            id="minPostedDate"
            disabled={disabled}
            placeholder={t('hiringIntent.placeholders.minPostedDate')}
            setSelected={(value) => handleChange('minPostedDate', value)}
            options={transformEnumToOptions({
              value: MinPostedPeriod,
              key: 'minPostedDate',
              baseLabel: 'hiringIntent',
              t
            })}
          />
        </Form.Item>
      </div>
    </div>
  );
}
