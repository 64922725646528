import { Dayjs } from 'dayjs';

import { Event, IntegrationToken, Lead, Persona, UserInterface } from '.';

import { HubspotConfigPersonalizationProperty } from '../components/Settings/components/Integrations/HubSpotConfigurationModal';

export enum CampaignStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  All = 'All'
}

export enum TestEmailProcessingStatus {
  generating = 'generating'
}

export interface CampaignEmail {
  subject: string;
  body: string;
}

export enum Days {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday'
}

/**
 * This interface is used in forms
 */
export interface SendTime extends Record<Days, { start: Dayjs | null; end: Dayjs | null }[]> {}

/**
 * This interface is used for data, which comes from BE in campaign
 */
export interface CampaignSendTime extends Record<Days, { start: string; end: string }[]> {}

export interface SendingPreferences {
  config: {
    timezone: number;
  };
  days: CampaignSendTime;
}

export interface TrackingPreferences {
  isActive: boolean;
}

export interface ContinuousIntentSearch {
  filterUrl: string;
  isIntentSearchActive: boolean;
}

export enum GeneratedConditions {
  emojis = 'emojis',
  leadsName = 'leadsName',
  leadsCompanyName = 'leadsCompanyName',
  lowerCaseLetters = 'lowerCaseLetters',
  plainLanguage = 'plainLanguage'
}

export interface GeneratedSubjectLineValues {
  emojis: boolean;
  leadsName: boolean;
  leadsCompanyName: boolean;
  lowerCaseLetters: boolean;
  plainLanguage: boolean;
}

export interface SubjectPreferences {
  custom?: {
    value: string;
    config?: object;
  };
  generated?: GeneratedSubjectLineValues;
}

export interface CampaignPreferences {
  unsubscribe?: {
    isActive?: boolean;
  };
  sms?: { isActive?: boolean };
  linkedinPersonalization?: { followUp?: boolean; outgoing?: boolean };
  aiSmartReplies?: { isActive?: boolean };
  videoReplica?: { isActive?: boolean; replicaId?: string };
  shouldEnrichHubspotContacts?: { isActive?: boolean };
  memesInEmails?: { isActive?: boolean; memeText?: string };
  hubspot?: {
    selectedPersonalizationProperties?: HubspotConfigPersonalizationProperty[];
    syncEvents?: { isActive?: boolean };
    createNewContacts?: { isActive?: boolean };
    createCallTasks?: { isActive?: boolean };
    createLinkedinTasks?: { isActive?: boolean };
  };
  linkedinAutomation?: {
    linkedinOutgoingMessage?: boolean;
    linkedinConnectionRequest?: boolean;
    linkedinOutgoingAfterSecondFollowUpEmail?: boolean;
  };
}

export interface Campaign {
  id: number;
  name: string;
  events: Event[];
  numberOfFollowUps: number;
  daysBetweenFollowUps: number;
  leads: Lead[];
  status: CampaignStatus;
  description: string;
  persona: Persona;
  owner: UserInterface;
  integrationTokens?: IntegrationToken[];
  replyEvents?: string;
  sendingPreferences?: SendingPreferences;
  subjectPreferences?: SubjectPreferences;
  trackingPreferences?: TrackingPreferences;
  campaignPreferences?: CampaignPreferences;
  intentSearch?: ContinuousIntentSearch;
  listId?: number;
  listName?: string;
  isListButtonDisabled?: boolean;
  // We need to add this field to make input respond faster
  memeText?: string;
  campaignOwnerId?: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CampaignMetrics {
  leadsEngagedWithAtLeastOneOutgoingEvent?: number;
  leadsEngagedRate?: number;
  responseRate?: number;
  bounceRate?: number;
  openRate?: number;
  positiveResponseRate?: number;
  unsubscribeRate?: number;
  meetingsBooked: number;
  totalEvents?: string;
  linkedinLeadsEngaged?: number;
  linkedinConnectionsSent?: number;
  linkedinConnectionAcceptanceRate?: number;
  linkedinMessagesSent?: number;
  linkedinResponseRate?: number;
}

export type CampaignWithMetrics = Campaign & CampaignMetrics;
